























import { Component, Mixins } from 'vue-property-decorator';

import { ListingFilter } from 'client-website-ts-library/filters';
import {
  ListingStatus,
  Listing,
} from 'client-website-ts-library/types';

import {
  ServiceManager,
  ServiceType,
  Firebase,
  API,
} from 'client-website-ts-library/services';

import { View } from 'client-website-ts-library/plugins';

import ListingCardCycler from '@/components/Cycler/ListingCardCycler.vue';
import Listings from '../components/Listings.vue';
import SideBySide from '../components/Layout/SideBySide.vue';
import ItemCycler from '../components/Cycler/ItemCycler.vue';
import ListingSearch from '../components/ListingSearch.vue';

@Component({
  components: {
    Listings,
    ListingSearch,
    SideBySide,
    ItemCycler,
    ListingCardCycler,
  },
})
export default class Home extends Mixins(View) {
  private featuredListings: Listing[] | null = null;

  private FeaturedlistingFilter: ListingFilter = new ListingFilter({
    Statuses: [ListingStatus.Current, ListingStatus.UnderContract],
    IsFeatured: true,
    PageSize: 6,
  });

  mounted() {
    this.loadFeaturedListings();
  }

  registerFCM(): void {
    const fcm = ServiceManager.Get(ServiceType.Firebase) as Firebase;

    fcm.Subscribe().then((token: string) => {
      console.log(token, this);
    });
  }

  loadFeaturedListings(): void {
    API.Listings.Search(this.FeaturedlistingFilter).then((listings) => {
      this.featuredListings = listings.Items;
    });
  }
}
