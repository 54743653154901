import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  // Residential
  {
    path: '/properties-for-sale',
    name: 'Properties For Sale',
    component: () => import('../views/PropertiesForSale.vue'),
  },
  {
    path: '/properties-for-rent',
    name: 'Properties For Rent',
    component: () => import('../views/PropertiesForRent.vue'),
  },
  {
    path: '/properties-sold',
    name: 'Properties Sold',
    component: () => import('../views/PropertiesSold.vue'),
  },
  {
    path: '/open-homes',
    name: 'Inspection Times',
    component: () => import('../views/InspectionTimes.vue'),
  },
  {
    path: '/request-an-appraisal',
    name: 'Sales Appraisal',
    component: () => import('../views/SalesAppraisal.vue'),
  },
  // Commercial
  {
    path: '/commercial-properties-for-sale',
    name: 'Commercial Properties For Sale',
    component: () => import('../views/CommercialPropertiesForSale.vue'),
  },
  {
    path: '/commercial-properties-for-rent',
    name: 'Commercial Properties For Rent',
    component: () => import('../views/CommercialPropertiesForRent.vue'),
  },
  {
    path: '/commercial-properties-sold',
    name: 'Commercial Properties Sold',
    component: () => import('../views/CommercialPropertiesSold.vue'),
  },
  // Off Market
  {
    path: '/off-market/introduction',
    name: 'Off Market',
    meta: {
      hide: true,
    },
    component: () => import('../views/OffMarketIntroduction.vue'),
  },
  {
    path: '/off-market/register',
    name: 'Off Market Registration',
    meta: {
      hide: true,
    },
    component: () => import('../views/OffMarketRegistration.vue'),
  },
  {
    path: '/off-market/properties-for-sale',
    name: 'Off Market Listings',
    meta: {
      hide: true,
    },
    component: () => import('../views/OffMarketListings.vue'),
  },
  // Forms
  {
    path: '/forms/tenancy-application',
    name: 'Tenancy Application Form',
    component: () => import('../views/TenancyApplicationForm.vue'),
  },
  {
    path: '/forms/notice-to-leave',
    name: 'Notice to Leave',
    component: () => import('../views/Form13.vue'),
  },
  {
    path: '/forms/maintenance-request',
    name: 'Maintenance Request',
    component: () => import('../views/MaintenanceRequest.vue'),
  },
  {
    path: '/forms/sales-appraisal',
    name: 'Sales Appraisal',
    component: () => import('../views/SalesAppraisal.vue'),
  },
  // Other
  {
    path: '/about-us',
    name: 'About',
    component: () => import('../views/About.vue'),
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: () => import('../views/ContactUs.vue'),
  },
  {
    path: '/testimonials',
    name: 'Testimonials',
    component: () => import('../views/Testimonials.vue'),
  },
  // Detail Pages
  {
    path: '/listings/:id',
    name: 'Listing',
    component: () => import('../views/ListingDetails.vue'),
  },
  {
    path: '/profiles/:id',
    name: 'Profile',
    component: () => import('../views/AgentProfile.vue'),
  },
  {
    path: '/sitemap',
    name: 'Sitemap',
    component: () => import('../views/Sitemap.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/*',
    name: 'Not Found',
    component: () => import('../views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
});

export default router;
